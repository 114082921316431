import Vue from 'vue'
import Breakpoints from '../vue/breakpoints'
import LazyLoad from "vanilla-lazyload";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

let masterplan = document.querySelector('#masterplan');

let masterplanNav = document.querySelector('.masterplan');

// Intiate Vuejs instance
if(masterplan == null) {
    var app = new Vue({
        el: '#app',
        delimiters: ["${","}"],
        components: {
            Breakpoints,
        }
    })
}


// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {

    // Lazyload
    var lazy = new LazyLoad({
        elements_selector: '.lazy'
    });

    // Mobile navigation
    const navTriggers = document.querySelectorAll('.js-nav-btn');
    const mobNav = document.querySelector('.modal');

    if(navTriggers.length){
        navTriggers.forEach(function(navTrigger) {
            bindEvent(navTrigger, 'click', function() {
                event.preventDefault();
                document.body.classList.toggle('modal-open');
                navTrigger.classList.toggle('js-nav-btn--active');
                mobNav.classList.toggle('modal--active');
                mobNav.setAttribute('aria-hidden', mobNav.getAttribute('aria-hidden') === 'true' ? 'false' : 'true');
                navTrigger.setAttribute('aria-expanded', navTrigger.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }, false);
        });
    }

    // Remove the modal-open class from body tag when Section available nav link clicked
    if(window.location.hash == '#masterplan') {
        setTimeout(function(){
            document.querySelector("#masterplan").scrollIntoView();
        }, 500);
    }
    
    bindEvent(masterplanNav, 'click', function() {
        if(navTriggers.length){
            navTriggers.forEach(function(navTrigger) {
                document.body.classList.remove('modal-open');
                navTrigger.classList.remove('js-nav-btn--active');
                mobNav.classList.remove('modal--active');
            });
        }
    }, false);

    // Horizonal scrolling - yahooo!
    // Using Green Sock
    const sliderWidth = document.querySelector('.slider__inner') ? document.querySelector('.slider__inner').offsetWidth - window.innerWidth : false;

    if (sliderWidth) {
        gsap.to('.slider__inner', {
            scrollTrigger:  {
                trigger: '.slider',
                scrub: true,
                pin: true,
                start: 'top top',
                end: '+='+(sliderWidth-100)
            },
            x: -sliderWidth
        });
    }


    


    // Accordions
    // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
    const accordionItems = document.querySelectorAll('[data-accordion-item]');

    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionLink = accordion.firstElementChild;
            bindEvent(accordionLink, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {
        const link = e.target;
        const parent = link.closest('[data-accordion]');
        const content = parent.querySelectorAll('[data-accordion-content]');

        content.forEach(function(content) {
            if (content.previousElementSibling === link) {
                content.classList.toggle('is-active');
                link.classList.toggle('is-active');
                link.setAttribute('aria-expanded', link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
            }
            else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('is-active');
                    content.previousElementSibling.classList.remove('is-active');
                    content.previousElementSibling.setAttribute('aria-expanded', 'false');
                }
            }
        });
    }


    // Home video
    const video = document.querySelector('.video video');
    const playButton = document.querySelector('.video__btn');

    if (video) {
        bindEvent(playButton, "click", function() {
            playButton.classList.toggle('video__btn--playing');
            if (video.paused == true) {
                video.play();
                playButton.innerHTML = '<i class="fas fa-pause"></i>';
            } else {
                video.pause();
                playButton.innerHTML = '<i class="fas fa-play"></i>';
            }
        });
    }


    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }
})
